import axios from '../utils/axiosConfig';
import { API_BASE_URL } from '../utils/config';
import { calculateChecksum } from '../utils/encryption';

const BASE_URL = `${API_BASE_URL}/s3`;

// 1. 生成預簽名 URL
export const generatePresignedUrl = async (sourceName, metadata) => {
  try {
    const response = await axios.post(`${BASE_URL}/generate_presigned_url`, {
      source_name: sourceName,
      metadata: metadata
    });
    return response.data;
  } catch (error) {
    console.error('生成預簽名 URL 失敗:', error);
    throw error;
  }
};

// 2. 上傳文件
export const uploadFile = async (files, user, md5, enableDeepAnalysis) => {
  try {
    const formData = new FormData();
    const info = [];

    for (const file of files) {
      formData.append('files', file);
      info.push({ source_checksum: md5, user_email: user, is_in_depth_analysis: enableDeepAnalysis });
    }

    formData.append('info', JSON.stringify(info));

    const response = await axios.post(`${BASE_URL}/upload_file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    // 檢查回應中的每個文件狀態
    for (const fileResult of response.data) {
      if (fileResult.status === 3) {
        throw new Error(fileResult.message);
      } else if (fileResult.status === 2) {
        throw new Error(`上傳文件 '${fileResult.source_info.name}' 失敗: ${fileResult.message}`);
      }
    }

    return response.data;
  } catch (error) {
    console.error('上傳文件失敗:', error);
    throw error;
  }
};

// 3. 上傳圖像用於視覺處理
export const uploadImageForVision = async (files, user, md5) => {
  try {
    const formData = new FormData();
    const info = [];

    for (const file of files) {
      formData.append('files', file);
      info.push({ source_checksum: md5, user_email: user });
    }

    formData.append('info', JSON.stringify(info));

    const response = await axios.post(`${BASE_URL}/upload_img`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('上傳圖像失敗:', error);
    throw error;
  }
};

// 4. 上傳 AI 工具素材
export const uploadAIToolsMaterial = async (files, user) => {
  try {
    const formData = new FormData();
    const info = [];

    for (const file of files) {
      formData.append('files', file);
      info.push({ user_email: user });
    }

    formData.append('info', JSON.stringify(info));

    const response = await axios.post(`${BASE_URL}/upload_ai_tools_material`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    // 檢查回應中的每個文件狀態
    for (const fileResult of response.data) {
      if (fileResult.status === 2) {
        throw new Error(`上傳文件 '${fileResult.source_info.name}' 失敗: ${fileResult.message}`);
      }
    }

    return response.data;
  } catch (error) {
    console.error('上傳 AI 工具素材失敗:', error);
    throw error;
  }
};

// 5. 上傳知識庫頭像
export const uploadKnowledgeBaseAvatar = async (file, userEmail) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    
    // 計算文件的 MD5
    const checksum = await calculateChecksum(file);
    
    const info = [{
      source_checksum: checksum,
      user_email: userEmail
    }];

    formData.append('info', JSON.stringify(info));

    const response = await axios.post(`${BASE_URL}/upload_knowledge_base_avatar`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.status === 2) {
      throw new Error(`上傳頭像失敗: ${response.data.message}`);
    }

    return response.data;
  } catch (error) {
    console.error('上傳知識庫頭像失敗:', error);
    throw error;
  }
};

// 6. 取得 AI 工具素材上傳預簽署 URL
export const generateAIToolsMaterialPresignedUrl = async (filename) => {
  try {
    const response = await axios.post(`${BASE_URL}/generate_ai_tools_material_presigned_url`, {
      filename: filename
    });
    return response.data;
  } catch (error) {
    console.error('取得 AI 工具素材上傳預簽署 URL 失敗:', error);
    throw error;
  }
};
