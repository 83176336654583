import React from 'react';

const LoadingAnimation = ({ status, ...props }) => {
  return (
    <div className="loading-wrap" {...props}>
      <div className="loading-waves">
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
      {status && <div className="loading-status">{status}</div>}
    </div>
  );
};

export default LoadingAnimation;
