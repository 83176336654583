import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { Button, Progress, Space, Table, message, Tooltip, Modal, Tag, Empty } from 'antd';
import { DownloadOutlined, DeleteOutlined, MessageOutlined } from '@ant-design/icons';
import ISO6391 from 'iso-639-1';
import './aiAssistant.scss';
import MeetingSummaryForm from './meetingSummaryForm';
import TranslationForm from './translationForm';
import AuthContext from '../../contexts/authProvider';
import { getAIToolHistoryByUserEmail, deleteAIToolHistory, AI_ASSISTANT_TYPES, AI_ASSISTANT_FILE_TYPES, getEventStatus } from '../../api/aiTools';
import { formatTimestamp, getDocumentType } from '../../utils/functionTool';
import { useNavigate } from 'react-router-dom';
import LoadingAnimation from '../../components/LoadingAnimation';

const getTypeConfig = (type) => {
  const configs = {
    [AI_ASSISTANT_TYPES.MEETING_SUMMARY]: {
      title: '微電智能助理 / 會議逐字稿 & 智能解析',
      processingTitle: '正在處理中',
      completedTitle: '已完成的項目',
      downloadButtons: [
        { text: '下載逐字稿', key: 'transcript' },
        { text: '下載分析', key: 'analysis' }
      ]
    },
    [AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION]: {
      title: '微電智能助理 / 文件翻譯',
      processingTitle: '翻譯處理中',
      completedTitle: '已完成翻譯',
      downloadButtons: [
        { text: '下載翻譯', key: 'translation' }
      ]
    }
  };
  return configs[type];
};

const getLanguageDisplay = (languageCode) => {
  if (languageCode === 'zh-TW') {
    return '繁體中文 (Traditional Chinese)';
  }
  const code = languageCode.split('-')[0];
  const name = ISO6391.getName(code);
  const nativeName = ISO6391.getNativeName(code);
  return nativeName ? `${name} (${nativeName})` : name;
};

const AIAssistant = ({ type = AI_ASSISTANT_TYPES.MEETING_SUMMARY }) => {
  const { user } = useContext(AuthContext);
  const config = getTypeConfig(type);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const dataRef = useRef(data);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 10;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const fetchData = useCallback(async (currentPage = 1, isLoadMore = false) => {
    setLoading(true);
    try {
      const response = await getAIToolHistoryByUserEmail({
        page: currentPage,
        pageSize,
        userEmail: user?.email,
        eventType: type
      });
      
      // 處理資料分組和排序
      const processingItems = [];
      const completedItems = [];
      
      response.forEach(item => {
        // 使用新的資料結構判斷處理狀態
        const isProcessing = item.event_status?.event_status === 'PROCESSING' || 
                           item.event_status?.event_status === 'UNKNOWN' ||
                           item.event_status?.event_status === 'STARTED' ||
                           item.event_status?.event_status === undefined;
        
        const processedItem = {
          ...item,
          // 將新的資料結構映射到原有的資料結構
          task: {
            task_progress: {
              status: item.event_status?.event_status,
              message: item.event_status?.event_message,
              progress: item.event_status?.event_progress
            },
            task_result: item.event_result
          },
          status: isProcessing ? 'PROCESSING' : 'COMPLETED',
          group: isProcessing ? '處理中項目' : '已完成項目'
        };
        
        if (isProcessing) {
          processingItems.push(processedItem);
        } else {
          completedItems.push(processedItem);
        }
      });
      
      // 已完成項目按時間排序
      completedItems.sort((a, b) => new Date(b.event_timestamp) - new Date(a.event_timestamp));
      
      // 合併處理中和已完成的項目
      const processedData = [...processingItems, ...completedItems];
      
      setData(prev => {
        // 檢查先前的 items 中是否有已標記為失敗 (task_progress.status === 'FAILURE') 的項目
        // 若有，則保留失敗狀態，避免 fetchData 回傳的新資料覆蓋本地的失敗資料
        const mergedData = processedData.map(item => {
          const localFailure = prev.find(p => p.event_id === item.event_id && p.task?.task_progress?.status === 'FAILURE');
          return localFailure ? localFailure : item;
        });
        return isLoadMore ? [...prev, ...mergedData] : mergedData;
      });
      setHasMore(processedData.length === pageSize);
    } catch (error) {
      message.error('獲取資料失敗');
    } finally {
      setLoading(false);
    }
  }, [type, user?.email]);

  const updateProcessingItems = useCallback(async () => {
    const processingItems = dataRef.current.filter(item => item.status === 'PROCESSING');
    if (processingItems.length === 0) return;

    const updatedItems = [];
    // 逐一處理每個 processing 項目，確保拿到 response 或 timeout 後再處理下一項
    for (const item of processingItems) {
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Request timeout')), 30000);
      });

      try {
        // 等待收到 getEventStatus 的回應或超時後才會繼續
        const statusData = await Promise.race([
          getEventStatus(item.event_id, item.task?.task_progress?.progress),
          timeoutPromise
        ]);

        const newStatus = statusData?.event_status;
        let updatedItem;
        if (newStatus === 'FAILURE') {
          updatedItem = {
            ...item,
            event_status: statusData,
            task: {
              ...item.task,
              task_progress: {
                status: 'FAILURE',
                message: statusData?.event_message,
                progress: statusData?.event_progress
              }
            },
            status: 'COMPLETED',
            group: '已完成項目'
          };
        } else {
          updatedItem = {
            ...item,
            task: {
              ...item.task,
              task_progress: {
                status: newStatus,
                message: statusData?.event_message,
                progress: statusData?.event_progress
              }
            },
            status: newStatus
          };
        }
        updatedItems.push(updatedItem);
      } catch (error) {
        // 若超時則保留原狀態，並記錄 warning
        if (error.message === 'Request timeout') {
          console.warn(`Request timeout for event ${item.event_id}`);
          updatedItems.push(item);
        } else {
          throw error;
        }
      }
    }

    setData(prevData => {
      const newData = [...prevData];
      updatedItems.forEach(updatedItem => {
        const index = newData.findIndex(item => item.event_id === updatedItem.event_id);
        if (index !== -1) {
          newData[index] = updatedItem;
        }
      });
      return newData;
    });

    // 如果所有處理中的項目均不再處於 PROCESSING 狀態則重新抓取資料
    if (updatedItems.every(item => item.status !== 'PROCESSING')) {
      fetchData(1, false);
    }
  }, [fetchData]);

  useEffect(() => {
    // 重置狀態並獲取數據
    setData([]);
    setPage(1);
    setHasMore(true);
    fetchData(1, false);
  }, [fetchData]);

  useEffect(() => {
    let timerId;

    const runUpdate = async () => {
      await updateProcessingItems();
      timerId = setTimeout(runUpdate, 5000);
    };

    runUpdate();

    return () => {
      clearTimeout(timerId);
    };
  }, [updateProcessingItems]);

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(nextPage, true);
  };

  const handleCancel = (record) => {
    Modal.confirm({
      title: '確認取消',
      content: '確定要終止處理並刪除該紀錄嗎？',
      okText: '確定',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        await deleteAIToolHistory(record.event_id);
        message.success('已取消處理並刪除紀錄');
        fetchData();
      }
    });
  };

  const handleDiscussion = async (record) => {
    const file_types = type === AI_ASSISTANT_TYPES.MEETING_SUMMARY
    ? [AI_ASSISTANT_FILE_TYPES.MATERIAL, AI_ASSISTANT_FILE_TYPES.MEDIA, AI_ASSISTANT_FILE_TYPES.SUMMARY]
    : [AI_ASSISTANT_FILE_TYPES.MATERIAL, AI_ASSISTANT_FILE_TYPES.TRANSLATE, AI_ASSISTANT_FILE_TYPES.DICTIONARY];
    // Get all file info based on file types
    const fileInfos = file_types.map(fileType => {
      const sourceKey = `source_${fileType}`;
      return record.event_result?.[sourceKey];
    }).filter(Boolean);

    if (!fileInfos.length) {
      message.error('無法取得檔案資訊');
      return;
    }

    // Convert each file info to file data
    const filesData = fileInfos.map(fileInfo => ({
      source_source: 'upload',
      source_name: fileInfo.source_name,
      source_type: 'text/plain', 
      source_url: fileInfo.source_url,
      source_content: fileInfo.source_content,
      source_checksum: fileInfo.source_checksum,
      dispatchStatus: 'pending',
      status: 1,
      isUploading: true,
      needsDispatch: true
    }));

    // Navigate to base askquestion path with file data
    navigate('/askquestion', { 
      state: { 
        tempUploadedFiles: filesData
      } 
    });
  };

  const getColumns = (isCompleted) => [
    {
      title: '檔案名稱',
      width: '25%',
      dataIndex: ['data', `${type === AI_ASSISTANT_TYPES.MEETING_SUMMARY ? 'media_path' : 'material_path'}`],
      key: 'file_name',
      render: (mediaPath) => {
        if (!mediaPath) return '-';
        return mediaPath.split('/').pop();
      }
    },
    {
      title: isCompleted ? '' : '狀態',
      width: '50%',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        if (status === 'PROCESSING') {
          return (
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Space>
                {type === AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION && (
                  <Tag color="blue">{getLanguageDisplay(record.data.target_language)}</Tag>
                )}
                <Tooltip title={record.task?.task_progress?.message || '處理中...'}>
                  <Progress 
                    percent={record.task?.task_progress?.progress || 0} 
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                  />
                </Tooltip>
              </Space>
              <Button 
                type="text" 
                danger 
                icon={<DeleteOutlined />}
                onClick={() => handleCancel(record)}
              >
                取消
              </Button>
            </Space>
          );
        }
        const detailStatus = record.event_status?.event_status;
        const isFailed = detailStatus === 'FAILURE' || detailStatus === 'ERROR';
        return (
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Space>
              <Tooltip title={record?.task?.task_progress?.message || record.event_status?.event_message || '處理中...'}>
                <Tag color={isFailed ? 'red' : 'green'}>{detailStatus}</Tag>
              </Tooltip>
              {type === AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION && (
                <Tag color="blue">{getLanguageDisplay(record.data.target_language)}</Tag>
              )}
            </Space>
            <Space>
              {!isFailed && (
                <>
                  {type === AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION ? (
                    <Button type="primary" icon={<DownloadOutlined />} onClick={() => handleDownload(record, AI_ASSISTANT_FILE_TYPES.TRANSLATE)}>
                      下載翻譯
                    </Button>
                  ) : (
                    <>
                      <Tag color="blue">{getDocumentType(record.data?.output_format)}</Tag>
                      <Button type="primary" icon={<DownloadOutlined />} onClick={() => handleDownload(record, AI_ASSISTANT_FILE_TYPES.MEDIA)}>
                        下載逐字稿
                      </Button>
                      <Button type="primary" icon={<DownloadOutlined />} onClick={() => handleDownload(record, AI_ASSISTANT_FILE_TYPES.SUMMARY)}>
                        下載分析
                      </Button>
                    </>
                  )}
                  <Button type="primary" icon={<MessageOutlined />} onClick={() => handleDiscussion(record)}>
                    討論
                  </Button>
                </>
              )}
            </Space>
          </Space>
        );
      }
    },
    {
      title: '建立時間',
      width: '25%',
      dataIndex: 'event_timestamp',
      key: 'created_at',
      render: (timestamp) => formatTimestamp(timestamp)
    }
  ];

  const handleDownload = async (record, fileType) => {
    try {
      const sourceKey = `source_${fileType}`;
      const fileInfo = record.event_result?.[sourceKey];
      
      if (!fileInfo) {
        throw new Error('檔案資訊不存在');
      }

      const file_content = fileInfo.source_content;
      const file_url = fileInfo.source_url;
      const original_file_name = fileInfo.source_name;

      // 目前只有逐字稿需要轉換副檔名
      const file_name = fileType === AI_ASSISTANT_FILE_TYPES.MEDIA 
        ? original_file_name.replace(/\.[^/.]+$/, '.txt') 
        : original_file_name;

      if (!file_url && !file_content) {
        throw new Error('檔案內容不存在');
      }

      if (fileType === AI_ASSISTANT_FILE_TYPES.SUMMARY || fileType === AI_ASSISTANT_FILE_TYPES.TRANSLATE) {
        // 分析檔案直接使用 S3 下載連結
        window.open(file_url, '_blank');
      } else {
        // 逐字稿使用 blob 下載
        const blob = new Blob([file_content], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      message.error('下載失敗: ' + error.message);
    }
  };

  return (
    <div className="ai-assistant-container">
      <h2>{config.title}</h2>
      
      <div className="section-container">
        <div className="section-header processing">
          <h3>{config.processingTitle}</h3>
        </div>
        <div className="section-content">
          <Table
            columns={getColumns(false)}
            dataSource={data.filter(item => item.status === 'PROCESSING')}
            rowKey="event_id"
            pagination={false}
            loading={{ spinning: loading, indicator: <LoadingAnimation /> }}
            locale={{
              emptyText: loading ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="讀取中" />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`沒有正在處理的${type === AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION ? '翻譯' : '逐字稿&分析'}`} />
              )
            }}
            rowClassName="processing-row"
          />
        </div>
      </div>

      <div className="section-container">
        <div className="section-header completed">
          <h3>{config.completedTitle}</h3>
        </div>
        <div className="section-content">
          <Table
            columns={getColumns(true)}
            dataSource={data.filter(item => item.status === 'COMPLETED')}
            rowKey="event_id"
            pagination={false}
            loading={{ spinning: loading, indicator: <LoadingAnimation /> }}
            locale={{
              emptyText: loading ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="讀取中" />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`沒有已完成的${type === AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION ? '翻譯' : '逐字稿&分析'}`} />
              )
            }}
            rowClassName="completed-row"
          />
          {hasMore && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Button onClick={handleLoadMore} loading={loading}>
                載入更多
              </Button>
            </div>
          )}
        </div>
      </div>

      <Button type="primary" className="ai-assistant-add-button" onClick={() => setIsModalVisible(true)}>
        新增項目
      </Button>

      {type === AI_ASSISTANT_TYPES.MEETING_SUMMARY && (
        <MeetingSummaryForm
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onAfterSubmit={(result) => {
            setIsModalVisible(false);
            fetchData();
          }}
          user={user}
        />
      )}
      {type === AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION && (
        <TranslationForm
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onAfterSubmit={(result) => {
            console.log('Form values:', result);
            setIsModalVisible(false);
            fetchData();
          }}
          user={user}
        />
      )}
    </div>
  );
};

export default AIAssistant;
