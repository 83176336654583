import React, { createContext, useState, useContext, useCallback, useRef } from 'react';
import { getChatSessionsByUserEmail } from '../api/ctrag';
import { message } from 'antd';

const ChatHistoryContext = createContext();

export const ChatHistoryProvider = ({ children }) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const isFetchedRef = useRef(false);

  const fetchChatHistory = useCallback(async () => {
    if (isFetchedRef.current) return;
    setLoading(true);
    try {
      const page_size = 20;
      const response = await getChatSessionsByUserEmail(1, page_size);
      const sortedHistory = response.sort((a, b) => 
        new Date(b.last_chat_timestamp) - new Date(a.last_chat_timestamp)
      );
      setChatHistory(sortedHistory);
      setPage(2);
      if (response.length < page_size) {
        setHasMore(false);
      }
      isFetchedRef.current = true;
    } catch (error) {
      console.error('獲取聊天歷史記錄失敗:', error);
      message.error('獲取聊天歷史記錄失敗');
      isFetchedRef.current = false;
    } finally {
      setLoading(false);
    }
  }, []);

  const loadMore = useCallback(async () => {
    if (!hasMore || loading) return;
    setLoading(true);
    try {
      const page_size = 20;
      const response = await getChatSessionsByUserEmail(page, page_size);
      if (response.length < page_size) {
        setHasMore(false);
      }
      setChatHistory(prev => {
        const combined = [...prev, ...response];
        return combined.sort((a, b) => 
          new Date(b.last_chat_timestamp) - new Date(a.last_chat_timestamp)
        );
      });
      setPage(prev => prev + 1);
    } catch (error) {
      console.error('載入更多聊天歷史記錄失敗:', error);
      message.error('載入更多聊天歷史記錄失敗');
    } finally {
      setLoading(false);
    }
  }, [hasMore, loading, page]);

  const refreshChatHistory = useCallback(() => {
    isFetchedRef.current = false;
    setPage(1);
    setHasMore(true);
    fetchChatHistory();
  }, [fetchChatHistory]);

  const addNewChat = useCallback((newChat) => {
    setChatHistory(prev => {
      const newHistory = [newChat, ...prev];
      return newHistory.sort((a, b) => 
        new Date(b.last_chat_timestamp) - new Date(a.last_chat_timestamp)
      );
    });
    setSelectedChatId(newChat.chat_session_id);
    return newChat;
  }, []);

  const clearSelectedChatId = useCallback(() => {
    setSelectedChatId(null);
  }, []);

  return (
    <ChatHistoryContext.Provider value={{
      chatHistory,
      loading,
      hasMore,
      selectedChatId,
      setSelectedChatId,
      clearSelectedChatId,
      fetchChatHistory,
      loadMore,
      refreshChatHistory,
      addNewChat
    }}>
      {children}
    </ChatHistoryContext.Provider>
  );
};

export const useChatHistory = () => {
  const context = useContext(ChatHistoryContext);
  if (!context) {
    throw new Error('useChatHistory must be used within a ChatHistoryProvider');
  }
  return context;
}; 