import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Layout, Menu, Typography, Button } from 'antd';
import { useLocation, Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  RightCircleOutlined,
  LeftCircleOutlined,
  StarOutlined,
  DeleteOutlined,
  HomeOutlined,
  FileTextOutlined,
  HistoryOutlined,
  MessageOutlined,
  MenuUnfoldOutlined,
  BookOutlined,
  FolderOutlined,
  ToolOutlined,
  TranslationOutlined,
} from '@ant-design/icons';
import useQueryParams from '../../hooks/useQueryParams';
import { useChatHistory } from '../../contexts/chatHistoryContext';
import { useKnowledgeBase } from '../../contexts/knowledgeBaseContext';
import { AI_ASSISTANT_TYPES } from '../../api/aiTools';
import LoadingAnimation from '../LoadingAnimation';

const { Link } = Typography;
const { Sider } = Layout;

function getItem(label, key, icon, children, disabled, type) {
  return {
    key,
    icon,
    children,
    label,
    disabled,
    type,
  };
}

const fileManagerMenuItems = [
  getItem(<Link href="/filemanager">微電能源</Link>, 'filemanager', <HomeOutlined />, null),
  getItem(
    <Link href="/filemanager?conditions[0][field]=starred&conditions[0][operation]=boolean&conditions[0][value]=true&conditions[1][field]=trashed&conditions[1][operation]=boolean&conditions[1][value]=false">
      已加星號
    </Link>,
    'starred',
    <StarOutlined />,
    null
  ),
  getItem(
    <Link href="/filemanager?conditions[0][field]=trashed&conditions[0][operation]=boolean&conditions[0][value]=true">
      垃圾桶
    </Link>,
    'trashed',
    <DeleteOutlined />,
    null
  ),
];

const usefulToolsMenuItems = [
  getItem(
    'AI工具箱',
    'ai-tools',
    <ToolOutlined />,
    [
      getItem(
        <RouterLink to={`/usefulTools/${AI_ASSISTANT_TYPES.MEETING_SUMMARY}`}>會議逐字稿&解析</RouterLink>,
        AI_ASSISTANT_TYPES.MEETING_SUMMARY,
        <FileTextOutlined />,
        null
      ),
      getItem(
        <RouterLink to={`/usefulTools/${AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION}`}>文件翻譯</RouterLink>,
        AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION,
        <TranslationOutlined />,
        null
      ),
    ],
    false,
    'group'
  ),
];

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const queryParams = useQueryParams();
  const location = useLocation();
  const navigate = useNavigate();
  
  const isAskQuestion = location.pathname.startsWith('/askquestion');
  const { chatHistory, loadMore, hasMore, fetchChatHistory, selectedChatId, setSelectedChatId } = useChatHistory();
  const { 
    knowledgeBases, 
    fetchKnowledgeBases, 
    hasMore: knowledgeBaseHasMore,  // 直接從 context 中解構 hasMore
    loadMore: loadMoreKnowledgeBase // 直接從 context 中解構 loadMore
  } = useKnowledgeBase();
  const [loadingStates, setLoadingStates] = useState({
    chat: false,
    loadMore: false,
    knowledgeBase: false
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setIsMobile(true);
        setHidden(true);
      } else {
        setIsMobile(false);
        setHidden(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setLoading = (key, value) => {
    setLoadingStates(prev => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    if (location.pathname.startsWith('/knowledgeBaseManager') && knowledgeBases.length === 0) {
      setLoading('knowledgeBase', true);
      fetchKnowledgeBases()
        .finally(() => {
          setLoading('knowledgeBase', false);
        });
    }
  }, [location.pathname, fetchKnowledgeBases, knowledgeBases.length]);

  useEffect(() => {
    if (isAskQuestion) {
      setLoading('chat', true);
      fetchChatHistory()
        .finally(() => {
          setLoading('chat', false);
        });
    }
  }, [isAskQuestion, fetchChatHistory]);

  // 滾動監聽
  useEffect(() => {
    const handleScroll = (e) => {
      const element = e.target;
      const isNearBottom = element.scrollHeight - element.scrollTop - element.clientHeight < 50;

      if (isAskQuestion && hasMore && !loadingStates.loadMore && isNearBottom) {
        setLoading('loadMore', true);
        loadMore()
          .finally(() => {
            setLoading('loadMore', false);
          });
      } else if (
        location.pathname.startsWith('/knowledgeBaseManager') && 
        knowledgeBaseHasMore && 
        !loadingStates.knowledgeBase && 
        isNearBottom
      ) {
        setLoading('knowledgeBase', true);
        loadMoreKnowledgeBase()
          .finally(() => {
            setLoading('knowledgeBase', false);
          });
      }
    };

    const siderElement = document.querySelector('.ant-layout-sider-children');
    if (siderElement) {
      siderElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (siderElement) {
        siderElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [
    hasMore, 
    loadingStates, 
    loadMore, 
    isAskQuestion,
    location.pathname,
    knowledgeBaseHasMore,
    loadMoreKnowledgeBase
  ]);

  const handleNewChat = useCallback((e) => {
    e.preventDefault();
    navigate('/askquestion', { state: { clearState: true } });
  }, [navigate]);

  const askQuestionMenuItems = useMemo(() => {
    if (loadingStates.chat) {
      return [
        getItem(
          <a href="/askquestion" onClick={handleNewChat}>開始新的聊天</a>, 
          'allQuestions', 
          <FileTextOutlined />, 
          null
        ),
        getItem(
          <LoadingAnimation />,
          'loading',
          <HistoryOutlined />,
          null,
          true
        ),
      ];
    }

    const now = new Date();
    const sevenDaysAgo = new Date(now - 7 * 24 * 60 * 60 * 1000);
    const thirtyDaysAgo = new Date(now - 30 * 24 * 60 * 60 * 1000);

    const groupedChats = {
      '今天': [],
      '最近 7 天': [],
      '最近 30 天': [],
      older: {}
    };

    const seenIds = new Set();

    chatHistory.forEach(chat => {
      if (seenIds.has(chat.chat_session_id)) return;
      seenIds.add(chat.chat_session_id);

      const chatDate = new Date(chat.last_chat_timestamp);
      const chatItem = getItem(
        <RouterLink to={`/askquestion/chat/${chat.chat_session_id}`}>{chat.chat_title}</RouterLink>,
        `chat-${chat.chat_session_id}`,
        <MessageOutlined />,
        null
      );

      const isToday = chatDate.toDateString() === now.toDateString();

      if (isToday) {
        groupedChats['今天'].push(chatItem);
      } else if (chatDate >= sevenDaysAgo) {
        groupedChats['最近 7 天'].push(chatItem);
      } else if (chatDate >= thirtyDaysAgo) {
        groupedChats['最近 30 天'].push(chatItem);
      } else {
        const yearMonth = `${chatDate.getFullYear()}/${String(chatDate.getMonth() + 1).padStart(2, '0')}`;
        if (!groupedChats.older[yearMonth]) {
          groupedChats.older[yearMonth] = [];
        }
        groupedChats.older[yearMonth].push(chatItem);
      }
    });

    const menuItems = [
      getItem(
        <a href="/askquestion" onClick={handleNewChat}>開始新的聊天</a>, 
        'allQuestions', 
        <FileTextOutlined />, 
        null
      ),
    ];

    if (groupedChats['今天'].length > 0) {
      menuItems.push(getItem('今天', 'today', null, groupedChats['今天'], false, 'group'));
    }

    // Add recent chats
    if (groupedChats['最近 7 天'].length > 0) {
      menuItems.push(getItem('最近 7 天', 'recent7', null, groupedChats['最近 7 天'], false, 'group'));
    }
    
    if (groupedChats['最近 30 天'].length > 0) {
      menuItems.push(getItem('最近 30 天', 'recent30', null, groupedChats['最近 30 天'], false, 'group'));
    }

    // Add older chats grouped by year/month
    Object.entries(groupedChats.older)
      .sort((a, b) => b[0].localeCompare(a[0])) // Sort by date descending
      .forEach(([yearMonth, chats]) => {
        menuItems.push(getItem(yearMonth, `month-${yearMonth}`, null, chats, false, 'group'));
      });

    // 移除原本的"顯示更多"按鈕，改為在底部顯示載入中動畫
    if (hasMore && loadingStates.loadMore) {
      menuItems.push(
        getItem(
          <LoadingAnimation />,
          'loading-more',
          null,
          null,
          true
        )
      );
    }

    return menuItems;
  }, [chatHistory, hasMore, loadingStates, handleNewChat]);

  const knowledgeBaseManagerMenuItems = useMemo(() => {
    if (loadingStates.knowledgeBase) {
      return [
        getItem(<Link href="/knowledgeBaseManager">新增知識庫</Link>, 'knowledgeBaseManager', <BookOutlined />, null),
        getItem(
          '已建立的知識庫',
          'knowledgeBaseList',
          <BookOutlined />,
          [getItem(<LoadingAnimation />, 'loading-kb', null, null, true)],
          false,
          'group'
        ),
      ];
    }

    const subMenuItems = knowledgeBases.sort((a, b) => a.knowledge_base_name.localeCompare(b.knowledge_base_name)).map(kb => 
      getItem(
        <RouterLink to={`/knowledgeBaseManager/${kb.knowledge_base_name}`}>
          {kb.knowledge_base_name}
        </RouterLink>,
        `kb-${kb.knowledge_base_name}`,
        <FolderOutlined />,
        null
      )
    );

    // 添加載入中動畫
    if (knowledgeBaseHasMore && loadingStates.knowledgeBase) {
      subMenuItems.push(
        getItem(
          <LoadingAnimation />,
          'loading-kb-more',
          null,
          null,
          true
        )
      );
    }

    return [
      getItem(<Link href="/knowledgeBaseManager">新增知識庫</Link>, 'knowledgeBaseManager', <BookOutlined />, null),
      getItem(
        '已建立的知識庫',
        'knowledgeBaseList',
        <BookOutlined />,
        subMenuItems,
        false,
        'group'
      ),
    ];
  }, [knowledgeBases, loadingStates, knowledgeBaseHasMore]);

  const handleMenuClick = ({ key }) => {
    if (key.startsWith('chat-')) {
      const chatId = key.replace('chat-', '');
      setSelectedChatId(chatId);
    }
  };

  const menuItems = useMemo(() => {
    if (location.pathname.startsWith('/askquestion')) {
      return askQuestionMenuItems;
    }
    if (location.pathname.startsWith('/knowledgeBaseManager')) {
      return knowledgeBaseManagerMenuItems;
    }
    if (location.pathname.startsWith('/usefulTools')) {
      return usefulToolsMenuItems;
    }
    return fileManagerMenuItems;
  }, [location.pathname, askQuestionMenuItems, knowledgeBaseManagerMenuItems]);

  const selectedKeys = useMemo(() => {
    if (location.pathname.startsWith('/askquestion')) {
      if (selectedChatId) {
        return [`chat-${selectedChatId}`];
      }
      const pathParts = location.pathname.split('/');
      if (pathParts[2] === 'chat') {
        return [`chat-${pathParts[3]}`];
      }
      return ['allQuestions'];
    }
    if (location.pathname.startsWith('/knowledgeBaseManager')) {
      const pathParts = location.pathname.split('/');
      if (pathParts.length > 2 && pathParts[2] !== '') {
        return [`kb-${decodeURIComponent(pathParts[2])}`];
      }
      return ['knowledgeBaseManager'];
    }
    if (location.pathname.startsWith('/usefulTools')) {
      const pathParts = location.pathname.split('/');
      return [pathParts[2] || AI_ASSISTANT_TYPES.MEETING_SUMMARY, 'ai-tools'];
    }
    const item = queryParams?.find?.(
      (item) => item.field === 'starred' || (item.field === 'trashed' && item.value === true)
    );
    return item ? [item.field] : ['filemanager'];
  }, [queryParams, location.pathname, selectedChatId]);

  const toggleSidebar = () => {
    if (isMobile) {
      setHidden(!hidden);
    }
  };

  return (
    <>
      {isMobile && hidden && (
        <Button
          className="sidebar-toggle"
          onClick={toggleSidebar}
          icon={<MenuUnfoldOutlined />}
        />
      )}
      <Sider
        collapsible
        className={`app-aside ${isMobile && hidden ? 'hidden' : ''}`}
        collapsed={collapsed}
        collapsedWidth={60}
        onCollapse={(value) => setCollapsed(value)}
        trigger={collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
      >
        <Menu
          mode="inline"
          className="app-aside-menu"
          inlineIndent={16}
          selectedKeys={selectedKeys}
          items={menuItems}
          onClick={handleMenuClick}
        />
      </Sider>
      {isMobile && !hidden && (
        <div className="sidebar-overlay" onClick={toggleSidebar}></div>
      )}
    </>
  );
};

export default Sidebar;
