import React, { Suspense, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';
import { Layout, Spin, ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import zhTW from 'antd/locale/zh_TW';
import { LoadingOutlined } from '@ant-design/icons';
import AuthContext from './contexts/authProvider';
import SettingContext from './contexts/settingProvider';
import Login from './pages/Login';
import SelectMenu from './pages/SelectMenu';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Auth from './pages/Auth';
import { KnowledgeBaseProvider } from './contexts/knowledgeBaseContext';
import { ChatHistoryProvider } from './contexts/chatHistoryContext';
import AIAssistant from './pages/usefulTools/aiAssistant';
import { AI_ASSISTANT_TYPES } from './api/aiTools';
import { useTranslation } from 'react-i18next';

const NotFound = React.lazy(() => import('./pages/NotFound'));
const FileManager = React.lazy(() => import('./pages/FileManager'));
const Projects = React.lazy(() => import('./pages/Projects'));
const Settings = React.lazy(() => import('./pages/Settings'));
const AskQuestion = React.lazy(() => import('./pages/AskQuestion'));
const KnowledgeBaseManager = React.lazy(() => import('./pages/knowledgeBaseManager'));
const UsefulTools = React.lazy(() => import('./pages/usefulTools'));

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
    }}
    spin
  />
);

function NavWrapper() {
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const locale = i18n.language === 'en' ? enUS : zhTW;

  if (!user) return <Navigate to="/login" />;

  if (pathname === '/projects') {
    return (
      <ConfigProvider locale={locale}>
        <Layout className={`app-layout ${dark ? 'dark' : ''}`}>
          <Header />
          <Suspense
            fallback={
              <div className="app-content loading" style={{ width: '100%' }}>
                <Spin indicator={antIcon} />
              </div>
            }
          >
            <Outlet />
          </Suspense>
          <Footer />
        </Layout>
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider locale={locale}>
      <Layout className={`app-layout ${dark ? 'dark' : ''}`}>
        <Header />
        <Layout>
          <Sidebar />
          <Layout>
            <Suspense
              fallback={
                <div className="app-content loading" style={{ width: '100%' }}>
                  <Spin indicator={antIcon} />
                </div>
              }
            >
              <Outlet />
            </Suspense>
            <Footer />
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/" element={
          <ChatHistoryProvider>
            <KnowledgeBaseProvider>
              <NavWrapper />
            </KnowledgeBaseProvider>
          </ChatHistoryProvider>
        }>
          <Route path="/" element={<Navigate replace to="/filemanager" />} />
          <Route path="/filemanager" element={<FileManager />} />
          <Route path="/filemanager/:id" element={<FileManager />} />
          <Route path="/filemanager/folders/:id" element={<FileManager />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/askquestion" element={<AskQuestion />} />
          <Route path="/askquestion/chat/:id" element={<AskQuestion />} />
          <Route path="/knowledgeBaseManager" element={<KnowledgeBaseManager />} />
          <Route path="/knowledgeBaseManager/:knowledge_base_name" element={<KnowledgeBaseManager />} />
          <Route path="/usefulTools" element={<UsefulTools />}>
            <Route path={AI_ASSISTANT_TYPES.MEETING_SUMMARY} element={<AIAssistant type={AI_ASSISTANT_TYPES.MEETING_SUMMARY} />} />
            <Route path={AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION} element={<AIAssistant type={AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION} />} />
          </Route>
        </Route>
        <Route path="/selectmenu" element={<SelectMenu />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
