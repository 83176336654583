import React, { useState, useEffect } from 'react'
import { Layout } from 'antd'
import useRWD from '../../hooks/useRWD';
import { useLocation } from 'react-router-dom'; 

const { Footer } = Layout
const date = new Date()

let clickCount = 0; // 將 clickCount 移到函數外部


const AppFooter = (props) => {
    const [isDevMode, setIsDevMode] = useState(false);
    const device = useRWD();
    const location = useLocation();

    // 檢查是否需要隱藏 footer - 使用 includes 來匹配所有包含 askquestion 的路徑
    const shouldHideFooter = location.pathname.includes('/askquestion') && device === 'mobile';

    useEffect(() => {
        const devModeStatus = localStorage.getItem('devMode');
        setIsDevMode(devModeStatus === 'true');
    }, []);

    const handleMicroClick = () => {
        clickCount++;
        if (clickCount === 8) {
            const newDevMode = !isDevMode;
            setIsDevMode(newDevMode);
            localStorage.setItem('devMode', newDevMode.toString());
            clickCount = 0;
            alert(newDevMode ? '已開啟開發者模式' : '已關閉開發者模式');
        }
    };

    // 如果需要隱藏，則返回 null
    if (shouldHideFooter) {
        return null;
    }

    return (
        <Footer className="app-footer" {...props}>
            © {date.getFullYear()} <span onClick={handleMicroClick}>Micro</span> Electricity Co., Ltd. All rights
            reserved.
        </Footer>
    )
}

export default AppFooter
