import React, { createContext, useState, useContext, useCallback } from 'react';
import { getLLMKnowledgeBaseByManager } from '../api/ctrag';
import { message } from 'antd';

const KnowledgeBaseContext = createContext();

export const KnowledgeBaseProvider = ({ children }) => {
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchKnowledgeBases = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getLLMKnowledgeBaseByManager();
      setKnowledgeBases(response || []);
      setHasMore(response && response.length > 0);
    } catch (error) {
      console.error('獲取知識庫列表失敗:', error);
      message.error('獲取知識庫列表失敗');
    } finally {
      setLoading(false);
    }
  }, []);

  const addKnowledgeBase = useCallback((newKnowledgeBase) => {
    setKnowledgeBases(prev => [...prev, newKnowledgeBase]);
  }, []);

  const updateKnowledgeBase = useCallback((updatedKnowledgeBase) => {
    setKnowledgeBases(prev => 
      prev.map(kb => 
        kb.knowledge_base_name === updatedKnowledgeBase.knowledge_base_name 
          ? updatedKnowledgeBase 
          : kb
      )
    );
  }, []);

  const removeKnowledgeBase = useCallback((knowledgeBaseName) => {
    setKnowledgeBases(prev => 
      prev.filter(kb => kb.knowledge_base_name !== knowledgeBaseName)
    );
  }, []);

  const loadMore = useCallback(async () => {
    // Implementation of loadMore method
  }, []);

  return (
    <KnowledgeBaseContext.Provider value={{
      knowledgeBases,
      loading,
      hasMore,
      loadMore,
      fetchKnowledgeBases,
      addKnowledgeBase,
      updateKnowledgeBase,
      removeKnowledgeBase
    }}>
      {children}
    </KnowledgeBaseContext.Provider>
  );
};

export const useKnowledgeBase = () => {
  const context = useContext(KnowledgeBaseContext);
  if (!context) {
    throw new Error('useKnowledgeBase must be used within a KnowledgeBaseProvider');
  }
  return context;
}; 