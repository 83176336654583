import React from 'react';
import { Select } from 'antd';
import ISO6391 from 'iso-639-1';

const LanguageSelector = ({ value, onChange, ...props }) => {
    const languageOptions = React.useMemo(() => {
        const languages = ISO6391.getAllNames();
        const codes = ISO6391.getAllCodes();
        
        // 建立基本語言選項
        const options = languages.map((name, index) => {
            const code = codes[index];
            const nativeName = ISO6391.getNativeName(code);
            
            // 特別處理簡體中文的顯示
            if (code === 'zh') {
                return {
                    value: code,
                    label: `Chinese (简体中文)`
                };
            }
            
            return {
                value: code,
                label: nativeName ? `${name} (${nativeName})` : name
            };
        });

        // 移除將要放在優先選項的語言
        const filteredOptions = options.filter(opt => 
            !['zh-TW', 'en', 'vi'].includes(opt.value)
        );

        // 建立優先語言選項
        const priorityOptions = [
            {
                value: 'zh-TW',
                label: `Traditional Chinese (繁體中文)`
            },
            {
                value: 'en',
                label: `English (英文)`
            },
            {
                value: 'vi',
                label: `Vietnamese (越南文)`
            }
        ];

        // 合併優先選項和其他選項
        return [...priorityOptions, ...filteredOptions];
    }, []);

    return (
        <Select
            value={value}
            onChange={onChange}
            options={languageOptions}
            showSearch
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            {...props}
        />
    );
};

export default LanguageSelector; 